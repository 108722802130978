import * as React from "react"

import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Nie znaleziono strony.</h1>
    <p>Ups! Ta strona nie istnieje. <Link to="/">Wróć</Link> na stronę główną.</p>
  </>
)

export default NotFoundPage
